export default class OrderNotary {

  static STATUS_ASSIGNED = 0;
  static STATUS_VIEWED = 1;
  static STATUS_DOCS_RECEIVED = 2;
  static STATUS_APPOINTMENT_SET = 3;
  static STATUS_APPOINTMENT_DECLINED = 4;
  static STATUS_APPOINTMENT_ACCEPTED = 5;
  static STATUS_DOCS_PRINTED = 6;
  static STATUS_DOCS_UPLOADED = 7;
  static STATUS_ORDER_COMPLETE = 8;
}