import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import SignatureCanvas from 'react-signature-canvas';
import { Button } from 'reactstrap';
import { useDocViewer } from '../Context';

const DocViewerSignPad = props => {

  /**
   * Component props:
   * field {object} the signature field
   * closeHandler {func} callback to call in order to dismiss the modal
   */
  const { field, closeHandler, isActive } = props;

  /**
   * Ref to the canvas dom object
   */
  const canvas = useRef(null);

  /**
   * Context vars (see: Context.js)
   */
  const { updateField } = useDocViewer();

  /**
   * Bool flag that tells that the user has begun to draw on canvas
   * 'Draw here' hint will be visible or not based on this value
   */
  const [isDrawingStarted, setIsDrawingStarted] = useState(false);

  /**
   * Bool flag that tells that the user has finished drawing on canvas
   * 'Clear' button will be visible or not based on this value
   */
  const [isDrawingEnded, setIsDrawingEnded] = useState(false);

  /**
   * State vars needed to calculate canvas width and height
   */
  const signPad = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  /**
   * We need to calculate the width/height of the canvas each time the 'Draw' tab becomes active
   * This is because if 'Type' is active by default, the canvas does not correctly inherit his size from the parent div
   * Probably because of display: none;
   */
  useEffect(() => {
    if (!isActive) {
      // this is not the active tab
      return;
    }
    const info = signPad.current.getBoundingClientRect();
    setWidth(info.width);
    setHeight(info.height);
  }, [isActive]);

  /**
   * Event handler called when the user clicks the 'Clear' button
   * Clears the canvas and resets some state vars
   */
  const clearPad = () => {
    canvas.current.clear();
    // show the draw hint
    setIsDrawingStarted(false);
    // hide the clear button
    setIsDrawingEnded(false);
  }

  /**
   * Event handler called when the user clicks the 'Apply' button
   * Updates the field with the signature
   */
  const applySignature = () => {
    // get the image representation of the canvas drawing
    const data = canvas.current.getTrimmedCanvas().toDataURL('image/png');
    // update the field with the signature
    updateField({
      ...field,
      signature: data,
    });
    // dismiss the modal
    closeHandler();
  }

  return <React.Fragment>
    <div className="doc-viewer-sign-pad" ref={signPad}>
      {!isDrawingStarted && <div className="hint">Draw here</div>}
      <SignatureCanvas ref={canvas} canvasProps={{ className: 'sign-canvas', width: width, height: height }} penColor="#1D71B8" dotSize={6} onBegin={() => setIsDrawingStarted(true)} onEnd={() => setIsDrawingEnded(true)} />
      {isDrawingEnded && <Button type="button" color="default" className="clear-btn" onClick={clearPad}>Clear</Button>}
    </div>
    <div className="modal-footer">
      <Button type="button" color="primary" onClick={applySignature} data-dismiss="modal">Apply</Button>
      <Button type="button" color="secondary" onClick={closeHandler} data-dismiss="modal">Close</Button>
    </div>
  </React.Fragment>
}

DocViewerSignPad.propTypes = {
  field: PropTypes.object,
  closeHandler: PropTypes.func,
  isActive: PropTypes.bool,
}

export default DocViewerSignPad;