import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Container } from "reactstrap";
import { generateCode, verifyCode } from "helpers/backendHelper";
import { useNavigate } from "react-router-dom";
import { route } from "helpers/routeHelper";
import { useAuth } from "context/auth";
import logoSrc from 'assets/images/mavsign-logo-white.svg';
import { showSuccess, showWarning } from "helpers/utilHelper";
import { useNavigator } from "context/navigator";
import { isUserVerifiedLocal, isVerifCodeSent, saveVerifCodeSent, saveVerificationToken } from "helpers/tokenHelper";
import { timestamp } from "helpers/dateHelper";
import { useSelector } from "react-redux";

let lastGenerateTs = 0;
const RESEND_CODE_RATE_LIMIT_WINDOW = 30; // number of seconds before which the user can resend verification code

const MobileContent = () => {

  const { user: authUser, refreshAuthUser } = useAuth();

  // router hook that helps redirect
  const navigate = useNavigate();

  // don't allow the user to navigate until the routes are initialized
  const { navIsReady } = useNavigator();

  /**** STATE ****/

  const [userVerified, setUserVerified] = useState(false);
  const [verifyError, setVerifyError] = useState(false);

  // get redux state from the store
  const directRoute = useSelector(state => state.Layout.directRoute);

  /**** EFFECTS ****/

  // runs once on component mount
  useEffect(() => {
    if (isUserVerifiedLocal()) {
      // if user is authenticated, go to home page
      navigate(route('home'));
    } else if (authUser && !isUserVerifiedLocal() && !isVerifCodeSent()) {
      // generate code if user is auth, but is not beign currently verified
      triggerCodeGenerate();
    }
    pasteEventListener();
  }, []);

  const inputChanged = (event) => {
    setVerifyError(false);
    const code = event.target.value;

    // Check if the input is exactly 4 digits long and contains only numbers
    if (/^\d{4}$/.test(code)) {
      verifyCode(code).then(res => {
        if (!res.token) {
          setVerifyError(true);
        } else {
          saveVerificationToken(res.token);
          setUserVerified(true);
          // refresh auth user
          refreshAuthUser();
        }
      }).catch(err => {
        setVerifyError(true);
      });
    }
  }

  /**** UTILS ****/

  const resendCode = () => {
    triggerCodeGenerate();
    clearCode();
    setVerifyError(false);
  }

  const clearCode = () => {
    $('.code-digit').val('');
  }

  const pasteEventListener = () => {
    $('.code-digit').on('paste', (e) => {
      e.preventDefault();
      var code = e.originalEvent.clipboardData.getData('text').trim(0,4);
      $('.code-digit').val(code);
      inputChanged(e);
    })
  }

  const triggerCodeGenerate = () => {
    if (timestamp() < lastGenerateTs + RESEND_CODE_RATE_LIMIT_WINDOW) {
      showWarning("Please try again in a few seconds.");
    } else {
      generateCode().then(res => {
        showSuccess("The verification code has been sent to your email address and phone number");
        // Save session variable in order to know a verification code was sent
        saveVerifCodeSent();
        lastGenerateTs = timestamp();
      }).catch(err => {
        showWarning("Please try again in a few seconds.");
      });
    }
  }

  const handleInput = (event) => {
    // Remove non-digit characters
    const value = event.target.value.replace(/\D/g, '');
    if (value.length > 4) {
      // Ensure the value doesn't exceed 4 digits
      event.target.value = value.slice(0, 4);
    } else {
      event.target.value = value;
    }
  }

  return <React.Fragment>
    <div id="mobileMenu">
      <Col className="text-center">
        <div id="topMenuWrapper">
          <div id="header">
            <div className="header-logo position-absolute">
              <img src={logoSrc} />
            </div>
          </div>
        </div>
      </Col>
    </div>
    <div id="welcome_wrapper" className="account-pages">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5} className="welcome-column">
            <Card className="overflow-hidden">
              <CardBody className="pt-3">
                <div className="p-2 mb-2">
                  <div className="welcome-title">Welcome message & Instructions</div>
                </div>
                <ul>
                  <li className="mb-2">
                    There are 3 main steps that need to be completed: ID Validation, e-Sign contracts and ink paper signatures on those documents that require it.
                  </li>
                  <li className="mb-2">
                    Please have a valid photo ID available. You will scan the front of the ID, the back of the ID with the barcode, and will take a selfie. Follow the instructions on the screen.
                  </li>
                  <li className="mb-2">
                    Upon reviewing the documents you will be guided to electronically sign the documents. Once completed the dealer representative will confirm that all signatures have been placed.
                  </li>
                  <li className="mb-2">
                    There are usually about 20 or so pages you will need to sign.
                  </li>
                  <li className="mb-2">
                    For the Wet Signed documents please print them out, sign and follow the instructions on the screen in order to scan them for the dealership to review.
                  </li>
                  <li className="mb-2">
                    Check your text messages or email for the verification code.
                  </li>
                </ul>
                <div id="codeWrapper" className={(verifyError ? 'invalid' : '') + (userVerified ? 'disabled' : '')}>
                  <input
                      onChange={inputChanged}
                      onInput={handleInput}
                      type="text"
                      inputMode="numeric"
                      className="code-digit"
                      maxLength="4"
                      pattern="\d{4}"
                      placeholder="Enter 4-digit code"
                    />
                  <div className="code_check_result_icon">
                    {userVerified && <i className="fas fa-check"></i>}
                  </div>
                </div>
                {verifyError && <label id="invalidCodeLabel">Code is invalid</label>}
                <div className="text-center">
                  <button onClick={resendCode} className="resend-btn btn" disabled={userVerified ? 'disabled' : ''}>Resend code</button>
                </div>
              </CardBody>
            </Card>
            <button onClick={() => navigate(directRoute ?? route('home'))} className={"btn btn-primary w-100 " + (!userVerified || !navIsReady() ? 'disabled' : '')}>Start</button>
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
}

export default MobileContent;