import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Input } from 'reactstrap';
import OrderDoc from 'model/orderDoc';
import { useDocViewer } from '../Context';
import trimCanvas from 'trim-canvas';

const DocViewerSignText = props => {

  /**
   * Component props:
   * field {object} the signature field
   * closeHandler {func} callback to call in order to dismiss the modal
   * isActive {boolean} TRUE if the typing tab is the active tab
   */
  const { field, closeHandler, isActive } = props;

  /**
   * Ref to the input dom object
   */
  const input = useRef(null);

  /**
   * Ref to the canvas dom object
   */
  const canvas = useRef(null);

  /**
   * Context vars (see: Context.js)
   */
  const { updateField } = useDocViewer();

  /**
   * Stores the text the user has typend into the text box
   */
  const [signature, setSignature] = useState('');

  /**
   * This effect runs whenever the tab is activated/deactivated
   * We use it to auto-focus the input field
   */
  useEffect(() => {
    // abort if the tab has just been deactivated
    if (!isActive) {
      return;
    }
    // focus the input field
    input.current.setSelectionRange(signature.length, signature.length);
    input.current.focus();
  }, [isActive]);

  /**
   * Event handler called when the user clicks the 'Apply' button
   * Updates the field with the signature
   */
  const applySignature = () => {
    // get info about the text box
    const inputRect = input.current.getBoundingClientRect();
    const inputFont = window.getComputedStyle(input.current).getPropertyValue('font');

    // paint the text unto the canvas
    const context = canvas.current.getContext('2d');
    context.canvas.width = inputRect.width;
    context.canvas.height = inputRect.height;
    context.textAlign = 'left';
    context.textBaseline = 'top';
    context.font = inputFont;
    context.fillStyle = '#1D71B8';
    context.fillText(signature, 10, 10);
    // trim the canvas whitespace
    trimCanvas(context.canvas);
    // get the image representation of the canvas text
    const data = context.canvas.toDataURL();
    // update the field with the signature
    updateField({
      ...field,
      signature: data,
    });
    // dismiss the modal
    closeHandler();
  }

  return <React.Fragment>
    <div className="doc-viewer-sign-text">
      <canvas ref={canvas} className="text-canvas"></canvas>
      <Input innerRef={input} type="text" className="form-control sign-text-field" placeholder="Type here" onChange={e => setSignature(e.target.value)} value={signature} />
    </div>
    <div className="modal-footer">
      <Button type="button" color="primary" onClick={applySignature} data-dismiss="modal">Apply</Button>
      <Button type="button" color="secondary" onClick={closeHandler} data-dismiss="modal">Close</Button>
    </div>
  </React.Fragment>
}

DocViewerSignText.propTypes = {
  field: PropTypes.object,
  closeHandler: PropTypes.func,
  isActive: PropTypes.bool,
}

export default DocViewerSignText;